
.App {
  .splash{
    height: 31vw;
    background-image: url('./landing_des.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;

    @media(max-width: 979px){
      background-image: url('./landing_mob.jpg');
      height: 70vh;
    }

    @media(max-width: 579px){
      height: 100vh;
    }

    .top{
      flex-grow: 1;

      .logo-wrapper{
        padding: 20px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        @media(max-width: 979px){
          opacity: 1;
        }
      }

      .tag{
        font-family: 'Coustard', serif;
        color: #093657;
        font-weight: 900;
        font-size: 1.5vw;
        padding-left: 8.5vw;

        @media(max-width: 979px){
          font-size: 2.5vh;
          padding-top: 4vh;
          text-align: center;
          padding-left: 0;
        }

        @media(max-width: 579px){
          padding-top: 7vh;

        }

        span:nth-child(1){
          display: block;
          font-size: 2.1vw;
          color: #FD671A;

          @media(max-width: 979px){
            font-size: 4vh;
          }
        }
        
        span:nth-child(3){
          display: block;
        }
      }
    }

    .bottom{
      text-align: center;
      position: sticky;
      top: unset;
      bottom: 0;
      padding-bottom: 40px;
      display: none;

      @media(max-width: 579px){
        display: block;
      }

      a{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        appearance: none;
        border: none;
        cursor: pointer;
        height: 66px;
        border-radius: 33px;
        background: linear-gradient(180deg, #F5B40E 0%, #FD671A 100%);
        box-shadow: 0px 2px 4px rgba(6, 48, 64, 0.25);
        font-weight: 800;
        font-size: 20px;
        color: #F5F5F5;
        text-transform: uppercase;
        padding: 0 30px;
      }
    }
  }

  .chat{
    .padder{
      height: 90px;
      background-color: #098eb7;

      @media(max-width: 779px){
        height: 70px;
        margin-top: -1px;
      }
    }

    .inner{
      background: linear-gradient(180deg, #222F58 0%, #240333 100%);
      color: white;

      .wrapper{
        display: flex;
        max-width: 1100px;
        padding: 0 40px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: -65px;

        @media(max-width: 779px){
          display: block;
          padding: 0;
        }

        .left{
          width: 25%;
          max-width: 25%;
          min-width: 25%;
          padding-right: 70px;
          font-family: 'Coustard', serif;

          @media(max-width: 779px){
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #098eb7;
          }

          img{
            width: 100%;
            height: auto;

            @media(max-width: 779px){
              max-width: 150px;
              margin-right: 30px;
              margin-bottom: -30px;
            }
          }

          div{
            background-color: #0F8EB7;
            font-weight: bold;
            font-size: clamp(20px, 2.4vw, 30px);
            padding: 15px;
            text-align: center;
            border-radius: 40px;
            margin-top: -30px;
            z-index: 2;
            position: relative;
            
            span{
              font-size: .6em;
              display: block;
              padding-bottom: 10px;
            }

            @media(max-width: 779px){
              margin-top: 0;
              background-color: transparent;
              flex-basis: 0;
              text-align: left;
              padding: 0;
              padding-top: 15px;
            }
          }
        }

        .right{
          flex-grow: 1;
          background: #08446F;
          border-radius: 60px;
          border: 3px solid #25C1CB;
          padding: 40px;
          overflow: hidden;

          @media(max-width: 779px){
            border-radius: 0;
            border: none;
            padding: 50px 20px 0;
            background: linear-gradient(180deg, #222F58 0%, #240333 100%)
          }

          .history{
            padding-bottom: 10px;
            min-height: calc(100vh - 350px);

            @media(max-width: 779px){
              min-height: unset;
            }

            &.messagesInvisible{
              .message{
                transform: translateY(-15px);
                opacity: 0;
                transition-delay: 0ms, 0ms !important;
              }
            }

            .message{
              width: fit-content;
              max-width: 75%;
              border-radius: 32px;
              margin-bottom: 40px;
              padding: 24px;
              transition: transform .4s, opacity .4s;

              p{
                margin: 0 0 6px;
              }

              &:nth-child(2){
                transition-delay: 400ms, 400ms;
              }

              &:nth-child(3){
                transition-delay: 800ms, 800ms;
              }

              h2{
                font-family: 'Coustard', serif;
                font-weight: 900;
                margin: 0;
                font-weight: bold;
                font-size: 2em;
                margin-top: -.2em;
                margin-bottom: .1em;
              }

              &.fromcm{
                background-color: white;
                border: 3px solid #2EC0B8;
                color: rgba(0, 0, 0, .8);
                border-top-left-radius: 0;

                h2{
                  color: #0B77A1;
                }
              }

              &.fromuser{
                margin-left: auto;
                font-weight: bold;
                background: linear-gradient(180deg, #25C1CB 0%, #0B77A1 100%);
                border-bottom-right-radius: 0;
                font-family: 'Coustard', serif;
              }
            }
          }

          .answers{
            margin: 0 -60px -60px;
            background: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);
            border-top: 3px solid #25C1CB;
            padding: 40px 60px 50px;
            text-align: center;

            @media(max-width: 779px){
              padding-bottom: 50px;
              margin: 0 -23px;
              border-left: 3px solid #25C1CB;
              border-right: 3px solid #25C1CB;
              border-top-left-radius: 30px;
              border-top-right-radius: 30px;
            }

            .messageWrapper{
              text-align: center;
              margin-bottom: 10px;
              margin-left: 5px;
              margin-right: 5px;

              .messageTrigger{
                appearance: none;
                text-decoration: none;
                cursor: pointer;
                font-size: 16px;
                color: #08446F;
                min-height: 50px;
                border-radius: 25px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0 30px;
                background-color: white;
                border: 2px solid white;
                font-weight: bold;
                text-transform: uppercase;

                &:hover{
                  opacity: .9;
                }

                &.done{
                  background-color: transparent;
                  color: white;
                }
              }
            }
          }
        }
      }

      footer{
        padding: 0 30px 30px;
        display: flex;
        justify-content: space-between;
        font-size: .9em;

        &>div{
          &:nth-child(1){
            max-width: 50%;
            min-width: 50%;
            width: 50%;

            @media(max-width: 779px){
              width: 100%;
              min-width: 100%;
              max-width: 100%;
              text-align: left;
            }
          }

          &:nth-child(2){
            text-align: right;
            flex-grow: 1;
            padding-left: 20px;

            @media(max-width: 779px){
              width: 100%;
              min-width: 100%;
              max-width: 100%;
              padding-left: 0;
            }

            @media(max-width: 779px){
              text-align: left;
            }
          }
          
          p{
            margin: 0;
            min-height: 1em;
          }
        }

        @media(max-width: 779px){
          margin-top: -30px;
          display: block;
          text-align: center;
        }

        a{
          color: white;
        }

        .langs{
          button{
            appearance: none;
            font: inherit;
            color: white;
            cursor: pointer;
            text-decoration: underline;
            background-color: unset;
            display: inline;
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
}

.privacy-terms{
  background-color: #222F58;
  color: white;
  padding: 30px;
  text-align: center;

  a{
    color: inherit !important;
  }
}